$theme: (
    'primary': #0D192B,
    'secondary': #323D4D,
    'third': #ABC7D9,

    'primaryLight': #172d4f,
    'primaryDark': #060b14,
    'secondaryLight': #47566c,
    'secondaryDark': #1e252e,
    'thirdLight': #cadce7,
    'thirdDark': #95b9d0,

    'success': #2ba44b,
    'error': #ff0001,
    'errorDark': #cc0000,
    'warning': #e4a300,
    'info': #006ba4,

    'dark': #262835,
    'light': #F8B400,
    'lightDark': #cc9600,
    'grey': #DBDFE1,
    'greyDark': #BAC1C5,
    'green': #48bdbb,
    'greenDark': #3da9a7,
    'whiteDark': #d9d9d9,
);

:export {
    @each $key, $value in $theme {
        #{unquote($key)}: $value;
    }
}